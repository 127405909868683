@import "src/styles/utils";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid $color-border-primary;
}

.right {
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .divider {
    height: 100%;
    width: 1px;
    background-color: $color-border-primary;
    margin: 0 14px;
  }
}

.form {
  width: 100%;
  max-width: 330px;
  padding: 30px 30px 6px;
  background: $color-white;
  box-shadow: $large-shadow;
  // border-radius: 12px;
}
