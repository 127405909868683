$font-base: "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-headings: $font-base;

$font-size: 14px;

// Colors

// Color Styles
$color-white: #ffffff;
$color-dark-blue: #2c475b;
$color-blue: #567286;
$color-background: #fbfdff;
$color-blue-menu: #f0f5ff;
$color-bed-light: #fff5f5;
$color-orange-light: #fff8ef;
$color-green-light: #f1ffe8;
$color-style: #263238;

// Ant
$color-magenta: #c41d7f;
$color-red: #cf1322;
$color-gold: #d48806;
$color-green: #389e0d;
$color-lime: #7cb305;
$color-default: #fafafa;

// Border
$color-border-primary: #dee7f5;

// Primary
$color-primary-default: #2d7cff;
$color-primary-hovered: #86b4ff;

// Type
$color-type-danger: #f86e6e;
$color-type-success: #95de64;
$color-type-warning: #ffc069;

// Shadows

$large-shadow: 0px 2px 10px rgba(45, 124, 255, 0.15);
$little-shadow: 1px 1px 1px rgba(212, 221, 237, 0.59);
$medium-shadow: 0px 2px 4px rgba(106, 145, 209, 0.1);
