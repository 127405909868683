@import "src/styles/utils";

.item {
  display: flex;
  align-items: center;
  color: $color-dark-blue;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &.red {
    background-color: $color-red;
  }
  &.magenta {
    background-color: $color-magenta;
  }
  &.gold {
    background-color: $color-gold;
  }
  &.green {
    background-color: $color-green;
  }
  &.lime {
    background-color: $color-lime;
  }
  &.default {
    background-color: $color-default;
  }
}

.main {
  display: flex;
  align-items: center;
}

.heading {
  margin: 0 10px;
}
.count {
  @include h5;
  margin-left: auto;
}
