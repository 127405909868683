@use "sass:math";

@mixin size($size) {
  width: $size;
  height: $size;
}

// Limit container width
@mixin limit-width($width: false) {
  @if $width {
    max-width: $width;
  }
  margin-left: auto;
  margin-right: auto;
}

// Customize scrollbars
@mixin customize-scrollbars(
  $size: 5px,
  $thumb: rgba($color-white, 0.1),
  $background: transparent,
  $visibility: hidden,
  $box-shadow: none
) {
  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-white;
    box-shadow: $box-shadow;
    border-radius: 10px;
    background: $background;
    visibility: $visibility;
  }

  &:hover::-webkit-scrollbar-track {
    visibility: visible;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $thumb;
    visibility: $visibility;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  // &::-webkit-scrollbar-thumb:window-inactive {
  // }
}

@mixin line-clamp($count: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin fonts($size: false, $lh: false, $color: false, $weight: false, $ls: false, $td: false, $tt: false) {
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $ls {
    letter-spacing: $ls;
  }
  @if $td {
    text-decoration: $td;
  }
  @if $tt {
    text-transform: $tt;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin wrapp($top: 0, $bottom: 0, $right: 0, $left: 0) {
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
}

@mixin resp-ratio($x, $y, $pseudo: false) {
  $padding: unquote(math.div($y, $x) * 100 + "%");
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}
