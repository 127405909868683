@mixin h1 {
  @include fonts(28px, 100%, $weight: 400);
}
@mixin h2 {
  @include fonts(22px, 100%, $weight: 400);
}
@mixin h3 {
  @include fonts(16px, 100%, $weight: 500);
}
@mixin h4 {
  @include fonts(14px, 100%, $weight: 600);
}
@mixin h5 {
  @include fonts(12px, 100%, $weight: 700);
}
@mixin h6 {
  @include fonts(10px, 100%);
}

@mixin main-body-text {
  @include fonts(14px, 100%, $weight: 400);
}

@mixin big-body-text {
  @include fonts(16px, 100%, $weight: 400);
}
