@import "reset";
@import-normalize;
@import "~antd/dist/antd.css";
@import "src/styles/utils";

body,
html {
  font-family: $font-base;
  background-color: $color-background;
  @include main-body-text;
  color: $color-blue;
}
