@import "src/styles/utils";

$content-width: 1400px;

.layout {
  min-height: 100vh !important;
  max-height: 100vh !important;
  overflow: hidden;
}

.sider {
  &Childrens {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.logo {
  padding: 10px;
  height: 64px;
  border-bottom: 1px solid $color-border-primary;
}

.stats {
  margin-top: auto;
  padding: 0 16px;
}

.bro {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  background-color: $color-white !important;
  border-bottom: 1px solid $color-border-primary;
  padding: 0 20px 0 16px !important;
}

.content {
  background-color: $color-white;
  padding: 20px;
  margin: 0 auto 16px;
  max-width: $content-width;
  &Wrapper {
    padding: 16px;
    overflow: auto;
  }
}
