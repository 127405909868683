@import "src/styles/utils";

.wrapper {
  display: flex;
  height: 100%;
}

.heading {
  margin-right: 24px;
  display: flex;
  align-items: center;
  h1 {
    @include h1;
  }
  .backBtn {
    font-size: 21px;
    color: $color-dark-blue;
    margin-right: 14px;
    cursor: pointer;
    &:hover {
      color: $color-primary-hovered;
    }
  }
}

.controls {
  @include big-body-text;
  color: $color-dark-blue;
  margin-left: auto;
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
  align-items: center;

  .user {
    display: flex;
    align-items: center;
    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: $color-primary-default;
      border-radius: 50%;
      margin-right: 10px;
      svg {
        color: $color-white;
      }
    }
  }

  .logout {
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $color-primary-hovered;
    }
  }
}
